<div class="container">
  <form [formGroup]="form">
    <div class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center header-title">
        <h2 class="title m-0">{{"WELLNESS.EDIT_MODAL.NEW_VIDEO" | translate}}</h2>
        <div class="flex-end">
          <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end" *ngIf="item.i18nName['EN'] && item.i18nName['IT']">
            <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
          </button>
          <button mat-icon-button type="button" align="end" *ngIf="!item.i18nName['EN'] && item.i18nName['IT'] || item.i18nName['EN'] && !item.i18nName['IT']">
            <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
          </button>
          <mat-menu #languageFormMenu="matMenu" overlapTrigger="false">
            <button mat-menu-item (click)="changeFormLanguage('it')">
              <mat-icon svgIcon="flag:italy"></mat-icon>
              <span>Italiano</span>
            </button>
            <button mat-menu-item (click)="changeFormLanguage('en')">
              <mat-icon svgIcon="flag:united-states"></mat-icon>
              <span>English</span>
            </button>
          </mat-menu>
        </div>
      </div>


      <div class="px-6 py-4 flex flex-col">
        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='it'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"WELLNESS.EDIT_MODAL.NAME_VIDEO" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <input formControlName="nome_it" matInput />
          </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.PUBLISH" | translate}}</mat-label>
              <input [matDatepicker]="datepickerRef" matInput formControlName="publish" required />
              <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datepickerRef></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.EXPIRY" | translate}}</mat-label>
              <input [matDatepicker]="datepickerRef1" matInput formControlName="expire" />
              <mat-datepicker-toggle [for]="datepickerRef1" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datepickerRef1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.PARENT" | translate}}</mat-label>
                <input
                type="text"
                matInput
                [formControlName]="'parentId'"
                [matAutocomplete]="auto"
                (keyup)="searchCat()"
                (focus)="searchCat()">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let item of categories" [value]="item">
                 {{item.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="flex-auto" *ngIf="!isBannerVideo">
              <mat-label>Pdf {{"GLOBAL.ITA" | translate}}</mat-label>
              <input formControlName="pdf_it" matInput />
            </mat-form-field>
            <mat-form-field class="flex-auto" *ngIf="isBannerVideo">
              <mat-label>Link bottone</mat-label>
              <input formControlName="category_anchor" matInput />
            </mat-form-field>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.VIDEO" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
              <input formControlName="video_it" matInput />
            </mat-form-field>
            <div class="flex-auto video-blocked">
              <mat-label>{{"WELLNESS.EDIT_MODAL.VIDEO_LOCKED" | translate}}</mat-label>
              <mat-slide-toggle formControlName="videoBlocked">
              </mat-slide-toggle>
            </div>
          </div>

          <mat-form-field class="description">
            <mat-label>{{"CHALLENGES.DESC" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <textarea cols="30" rows="8" matInput formControlName="desc_it"></textarea>
          </mat-form-field>
        </div>

        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='en'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"WELLNESS.EDIT_MODAL.NAME_VIDEO" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <input formControlName="nome_en" matInput />
          </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.PUBLISH" | translate}}</mat-label>
              <input [matDatepicker]="datepickerRef" matInput formControlName="publish" required />
              <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datepickerRef></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.EXPIRY" | translate}}</mat-label>
              <input [matDatepicker]="datepickerRef1" matInput formControlName="expire" />
              <mat-datepicker-toggle [for]="datepickerRef1" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datepickerRef1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.PARENT" | translate}}</mat-label>
                <input
                type="text"
                matInput
                [formControlName]="'parentId'"
                [matAutocomplete]="auto"
                (keyup)="searchCat()"
                (focus)="searchCat()">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let item of categories" [value]="item">
                 {{item.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="flex-auto" *ngIf="!isBannerVideo">
              <mat-label>Pdf {{"GLOBAL.ENG" | translate}}</mat-label>
              <input formControlName="pdf_en" matInput />
            </mat-form-field>
            <mat-form-field class="flex-auto" *ngIf="isBannerVideo">
              <mat-label>Link button</mat-label>
              <input formControlName="category_anchor" matInput />
            </mat-form-field>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.VIDEO" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
              <input formControlName="video_en" matInput />
            </mat-form-field>
            <div class="flex-auto video-blocked">
              <mat-label>{{"WELLNESS.EDIT_MODAL.VIDEO_LOCKED" | translate}}</mat-label>
              <mat-slide-toggle formControlName="videoBlocked">
              </mat-slide-toggle>
            </div>
          </div>

          <mat-form-field class="description">
            <mat-label>{{"CHALLENGES.DESC" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <textarea cols="30" rows="8" matInput formControlName="desc_en"></textarea>
          </mat-form-field>
        </div>



        <div class="bottoni">
          <button (click)="confirmClose()" mat-button-base class="m-4">{{"GLOBAL.CLOSE" | translate}}</button>
          <button mat-raised-button [disabled]="form.invalid" (click)="submit()" class="m-4 bottone-viola">
            {{"GLOBAL.CREATE" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
