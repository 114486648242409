import { CommonModule, formatDate } from '@angular/common';
import { Component, Inject, type OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { CategorieComponent } from '../categorie.component';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import { element } from 'protractor';

@Component({
  selector: 'vex-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
})
export class EditCategoryComponent implements OnInit {
  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  categories = []
  allCategories = [];

  constructor(private fb: UntypedFormBuilder, public translate: TranslateService, public restService: RestService, public guiService: GuiService,private dialogRef: MatDialogRef<CategorieComponent>,private dialog: MatDialog,@Inject(MAT_DIALOG_DATA) private item: any['item']){

  }

  ngOnInit(): void {
    this.search()
    console.debug(this.item)
    this.changeFormLanguage(this.translate.currentLang);
    this.form = this.fb.group({
      nome_it:[this.item && this.item.i18nName ? this.item.i18nName['IT'] : null],
      nome_en:[this.item && this.item.i18nName ? this.item.i18nName['EN'] : null],
      desc_it: [this.item && this.item.i18nDescription ? this.item.i18nDescription['IT'] : null],
      desc_en: [this.item && this.item.i18nDescription ? this.item.i18nDescription['EN'] : null],
      parentId: []
    })
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

submit(){
  let category = {
    name: this.form.value.nome_it,
    description: this.form.value.desc_it,
    i18nDescription: {
      EN: this.form.value.desc_en,
      IT: this.form.value.desc_it
    },
    i18nName: {
      EN: this.form.value.nome_en,
      IT: this.form.value.nome_it
    },
    parentId: this.form.value.parentId ? this.form.value.parentId.id : null,
    videos: this.item.videos
  }

  this.restService.putService('category', '/'+this.item.id + '?ALL_LANGUAGES=true', category).subscribe((ris)=>{
    this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), "OK!")
    this.dialogRef.close('done')
  })
}

search() {
  setTimeout(() => { this.guiService.showSpinner = true; }, 100);
  this.restService.postService('category', '/search?ALL_LANGUAGES=true',{page: 0, size: 100}).subscribe((ris) => {
    let tmp = []
    console.debug(ris)
    for (let item of ris.items) {
      if (!item.parentId) {
        tmp.push(item)
      }
    }
    this.categories = tmp.sort((a, b) => a.name.localeCompare(b.name));
    this.allCategories = tmp.sort((a, b) => a.name.localeCompare(b.name));
    this.form.get('parentId').setValue(this.allCategories.find(element => element.id === this.item.parentId))
    this.guiService.showSpinner = false;
  })
}

confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}

searchCat(){
  if(this.form.value.parentId && this.form.value.parentId.length > 3){
    this.categories = this.allCategories.filter(element => element.name.toLowerCase().includes(this.form.value.parentId.toLowerCase()))
  }else{
    this.categories = this.allCategories;
  }
}

displayFn(option: any): string {
  return option&& option.name ? option.name : '';
}

}
