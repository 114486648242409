import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-confirm-renew-modal',
  templateUrl: './confirm-renew-modal.component.html',
  styleUrls: ['./confirm-renew-modal.component.scss']
})
export class ConfirmRenewModalComponent implements OnInit {

  constructor(public translate: TranslateService, private dialogRef: MatDialogRef<ConfirmRenewModalComponent>) { }

  ngOnInit(): void {
  }

  confirm(){
    this.dialogRef.close('done')
  }
}
