import { CommonModule, formatDate } from '@angular/common';
import { Component, Inject, type OnInit } from '@angular/core';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { CategorieComponent } from '../categorie.component';

@Component({
  selector: 'vex-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.scss'],
})
export class EditVideoComponent implements OnInit {

  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  categories = [];
  allCategories = [];
  isBannerVideo: boolean;

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService, public restService: RestService, public guiService: GuiService, private dialogRef: MatDialogRef<CategorieComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public item: any['item']) {

  }

  ngOnInit(): void {
    this.search()
    console.debug(this.item)

    let tmpVideo = this.item.category.videos[this.item.videoIndex]
    this.isBannerVideo = this.item.category.name && this.item.category.name.toLowerCase() === "banner" ? true : false;

    this.form = this.fb.group({
      nome_it: [tmpVideo.i18nName['IT'] ? tmpVideo.i18nName['IT'] : null, Validators.required],
      nome_en: [tmpVideo.i18nName['EN'] ? tmpVideo.i18nName['EN'] : null, Validators.required],
      publish: [tmpVideo.publishDate ? formatDate(tmpVideo.publishDate, 'yyyy-MM-dd', 'en') : null, Validators.required],
      expire: [tmpVideo.expirationDate ? formatDate(tmpVideo.expirationDate, 'yyyy-MM-dd', 'en') : null],
      desc_it: [tmpVideo.i18nDescription['IT'] ? tmpVideo.i18nDescription['IT'] : null],
      desc_en: [tmpVideo.i18nDescription['EN'] ? tmpVideo.i18nDescription['EN'] : null],
      parentId: [null, Validators.required],
      video_it: [],
      video_en: [],
      videoBlocked: [tmpVideo.videoBlocked ? tmpVideo.videoBlocked : false],
      pdf_it: [],
      pdf_en: [],
      category_anchor: []
    })
    this.populateVideo(tmpVideo,this.isBannerVideo)
    if(this.item.category.i18nName['EN'] && this.item.category.i18nName['IT']){
      console.debug('TUTTE E DUE LE CHIAVI PRESENTI')
      this.changeFormLanguage(this.translate.currentLang);
    }else{
      console.debug('E PRESENTE SOLO UNA CHIAVE')
      if(this.item.category.i18nName['EN']){
        this.changeFormLanguage('en')
      }else{
        this.changeFormLanguage('it')
      }
    }
  }

  populateVideo(tmpVideo,isBannerVideo) {
    if (tmpVideo['i18nvideoId']) {
      this.form.get('video_it').setValue(tmpVideo['i18nvideoId']['IT'] ? tmpVideo['i18nvideoId']['IT'] : null);
      this.form.get('video_en').setValue(tmpVideo['i18nvideoId']['EN'] ? tmpVideo['i18nvideoId']['EN'] : null)
    } else if (tmpVideo['i18nVideoIds']) {
      this.form.get('video_it').setValue(tmpVideo['i18nVideoIds']['IT'] ? tmpVideo['i18nVideoIds']['IT'] : null);
      this.form.get('video_en').setValue(tmpVideo['i18nVideoIds']['EN'] ? tmpVideo['i18nVideoIds']['EN'] : null)
    }

    if(isBannerVideo){
      this.form.get('category_anchor').setValue(tmpVideo.pdf)
    }else{
     let pdfParsed = tmpVideo.pdf ? JSON.parse(tmpVideo.pdf) : null
     if(pdfParsed){
        pdfParsed['IT'] ? this.form.get('pdf_it').setValue(pdfParsed['IT']) : null;
        pdfParsed['EN'] ? this.form.get('pdf_en').setValue(pdfParsed['EN']) : null;
     }
    }
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];

    if (this.formLang) {
      this.form.controls['nome_' + this.formLang].clearValidators();
      this.form.get('nome_' + this.formLang).updateValueAndValidity();

      this.form.controls['desc_' + this.formLang].clearValidators();
      this.form.get('desc_' + this.formLang).updateValueAndValidity();

      this.form.controls['video_' + this.formLang].clearValidators();
      this.form.get('video_' + this.formLang).updateValueAndValidity();
    }

    this.form.controls['nome_' + lang].addValidators(Validators.required)
    this.form.controls['desc_' + lang].addValidators(Validators.required)
    this.form.controls['video_' + lang].addValidators(Validators.required)

    this.formLang = lang;
  }

  submit() {
    let video = {
      expirationDate: this.form.value.expire,
      name: this.form.value.nome_it,
      description: this.form.value.desc_it,
      i18nDescription: {
        EN: this.form.value.desc_en,
        IT: this.form.value.desc_it
      },
      i18nName: {
        EN: this.form.value.nome_en,
        IT: this.form.value.nome_it
      },
      publishDate: this.form.value.publish,
      i18nvideoId: {
        EN: this.form.value.video_en,
        IT: this.form.value.video_it
      },
      parentId: this.form.value.parentId ? this.form.value.parentId.id : null,
      videoBlocked: this.form.value.videoBlocked,
      pdf: this.isBannerVideo ? this.form.value.category_anchor : JSON.stringify({'EN': this.form.value.pdf_en, 'IT': this.form.value.pdf_it})
    }

    this.item.category.videos[this.item.videoIndex] = video;

    console.debug(this.item.category)

    setTimeout(() => { this.guiService.showSpinner = true }, 100);
    if (this.item.category.id === this.form.value.parentId.id) {
      this.restService.putService('category', '/'+this.item.category.id, this.item.category).subscribe((ris)=>{
        this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), "OK!")
        this.dialogRef.close('done')
        this.guiService.showSpinner = false
      })
    } else {
      console.debug('PARENT ID DIVERSO!!!!')

      this.restService.getService('category', '/' + this.form.value.parentId.id + '?ALL_LANGUAGES=true').subscribe((ris) => {
        let tmpCategory = ris;
        if (tmpCategory.videos) {
          tmpCategory.videos.push(video)
        } else {
          tmpCategory.videos = [];
          tmpCategory.videos.push(video)
        }
        this.restService.putService('category', '/' + this.form.value.parentId.id, tmpCategory).subscribe((res) => {

          /* UNA VOLTA FINITE TUTTE LE OPERAZIONI SONO SICURO DI POTER RIMUOVERE IL VIDEO DALLA CATEGORIA ORIGINARIA */
          this.item.category.videos.splice(this.item.videoIndex, 1);

          this.restService.putService('category', '/'+this.item.category.id, this.item.category).subscribe((resp)=>{
            this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), "OK!")
            this.dialogRef.close('done')
            this.guiService.showSpinner = false
          })
        })
      })
    }

  }

  search() {
    setTimeout(() => { this.guiService.showSpinner = true }, 100);
    this.restService.postService('category', '/search?ALL_LANGUAGES=true', {page: 0, size: 100}).subscribe((ris) => {
      let tmp = []
      console.debug(ris)
      for (let item of ris.items) {
        tmp.push(item)
      }
      tmp.forEach(element => {
        element.name = this.findParentCat(tmp,element.parentId) ? this.findParentCat(tmp,element.parentId) + ' > '+  element.name : element.name
      });
      this.categories = tmp.sort((a, b) => a.name.localeCompare(b.name));
      this.allCategories = tmp.sort((a, b) => a.name.localeCompare(b.name));
      if(this.item && this.item.category && this.item.category.id){
        this.form.get('parentId').setValue(this.allCategories.find(element => element.id === this.item.category.id))
      }
      this.guiService.showSpinner = false;
    })
  }

  confirmClose() {
    this.dialog.open(CloseConfirmComponent, {
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris) => {
      if (ris == 'true') {
        this.dialogRef.close()
      }
    })
  }

  searchCat(){
    if(this.form.value.parentId && this.form.value.parentId.length > 3){
      console.debug('RICERCA IN CORSO....')
      this.categories = this.allCategories.filter(element => element.name.toLowerCase().includes(this.form.value.parentId.toLowerCase()))
    }else{
      this.categories = this.allCategories;
    }
  }

  displayFn(option: any): string {
    return option&& option.name ? option.name : '';
  }

  findParentCat(arrCat,parentId){
    return arrCat.find(element => element.id === parentId)?.name;
  }

  onSelectionChangeCategory(event){
    event.option.value.name && event.option.value.name.toLowerCase() === 'banner' ? this.isBannerVideo = true : this.isBannerVideo = false;
  }

}
