<div class="container">
  <h1>
    <span class="back-button" (click)="backButton()">
      <mat-icon svgIcon="mat:arrow_back_ios"></mat-icon>
    </span>
    {{wellnessSrv.categoryName}}
  </h1>
  <div class="grid grid-cols-1 gap-4 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
    <div *ngFor="let video of videos; let i = index" style="position: relative; margin-inline: auto;" class="sub-video">
      <span
        (click)="videoDetail(video,wellnessSrv.macroAreaBack.name,wellnessSrv.macroAreaBack)">
        <div class="video-area">
          <img *ngIf="video.i18nvideoId && video.i18nvideoId[translate.currentLang.toUpperCase()]"
            class="vidyard-player-embed"
            [src]="'https://play.vidyard.com/'+video.i18nvideoId[translate.currentLang.toUpperCase()]+'.jpg'" data-v="4"
            data-type="inline" />
        </div>
        <p>{{video.name}}</p>
        <div class="locked" *ngIf="video.videoBlocked">
          <img src="../../../assets/img/Lucchetto.png" alt="" class="lock-icon">
        </div>
      </span>
    </div>
  </div>
</div>
