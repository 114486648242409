import { CommonModule } from '@angular/common';
import { Component, Inject, type OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { CategorieComponent } from '../categorie.component';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';

@Component({
  selector: 'vex-new-video',
  templateUrl: './new-video.component.html',
  styleUrls: ['./new-video.component.scss'],
})
export class NewVideoComponent implements OnInit {

  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  categories = [];
  allCategories = [];
  isBannerVideo: boolean;

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService, public restService: RestService, public guiService: GuiService,private dialogRef: MatDialogRef<CategorieComponent>,private dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public item: any['item']){

  }

  ngOnInit(): void {
    this.search()
    console.debug(this.item)
    this.isBannerVideo = this.item.name && this.item.name.toLowerCase() === "banner" ? true : false;

    this.form = this.fb.group({
      nome_it:[null],
      nome_en:[null],
      publish: [null, Validators.required],
      expire: [null],
      desc_it: [null],
      desc_en: [null],
      parentId: [{value: this.item && this.item.id? this.item.id : null, disabled: true}, Validators.required],
      video_it: [null],
      video_en: [null],
      videoBlocked: [true],
      pdf_it: [null],
      pdf_en: [null],
      category_anchor: []
    })
    if(this.item.i18nName['EN'] && this.item.i18nName['IT']){
      console.debug('TUTTE E DUE LE CHIAVI PRESENTI')
      this.changeFormLanguage(this.translate.currentLang);
    }else{
      console.debug('E PRESENTE SOLO UNA CHIAVE')
      if(this.item.i18nName['EN']){
        this.changeFormLanguage('en')
      }else{
        this.changeFormLanguage('it')
      }
    }
  }

  changeFormLanguage(lang) {
    console.debug(lang)
    this.iconFormFlag = this.flags[lang];
    if(this.formLang){
      this.form.controls['nome_'+this.formLang].clearValidators();
      this.form.get('nome_'+this.formLang).updateValueAndValidity();

      this.form.controls['desc_'+this.formLang].clearValidators();
      this.form.get('desc_'+this.formLang).updateValueAndValidity();

      this.form.controls['video_'+this.formLang].clearValidators();
      this.form.get('video_'+this.formLang).updateValueAndValidity();
    }

      this.form.controls['nome_'+lang].addValidators(Validators.required)
      this.form.controls['desc_'+lang].addValidators(Validators.required)
      this.form.controls['video_'+lang].addValidators(Validators.required)

      this.formLang = lang;
}

submit(){
  let video = {
    expirationDate: this.form.value.expire,
    name: this.form.value.nome_it,
    description: this.form.value.desc_it,
    i18nDescription: {
      EN: this.form.value.desc_en,
      IT: this.form.value.desc_it
    },
    i18nName: {
      EN: this.form.value.nome_en,
      IT: this.form.value.nome_it
    },
    publishDate: this.form.value.publish,
    i18nvideoId: {
      EN: this.form.value.video_en,
      IT: this.form.value.video_it
    },
    parentId: this.form.value.parentId ? this.form.value.parentId.id : null,
    videoBlocked: this.form.value.videoBlocked,
    pdf: this.isBannerVideo ? this.form.value.category_anchor : JSON.stringify({'EN': this.form.value.pdf_en, 'IT': this.form.value.pdf_it})
  }

  this.item.videos ? null : this.item.videos = [];
  this.item.videos.push(video)

  console.debug(this.item)
  setTimeout(() => { this.guiService.showSpinner = true }, 100);
  this.restService.putService('category', '/'+this.item.id, this.item).subscribe((ris)=>{
    this.guiService.openSnackBar(this.translate.instant('GLOBAL.NEW_SUCCESS'), "OK!")
    this.guiService.showSpinner = false
    this.dialogRef.close('done')
  })
}

search() {
  setTimeout(() => { this.guiService.showSpinner = true }, 100);
  this.restService.postService('category', '/search?ALL_LANGUAGES=true',{}).subscribe((ris) => {
    let tmp = []
    for (let item of ris.items) {
      tmp.push(item)
    }
    tmp.forEach(element => {
      element.name = this.findParentCat(tmp,element.parentId) ? this.findParentCat(tmp,element.parentId) + ' > '+  element.name : element.name
    });
    this.categories = tmp;
    this.allCategories = tmp;
    this.guiService.showSpinner = false;
  })
}

confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}

searchCat(){
  if(this.form.value.parentId && this.form.value.parentId.length > 3){
    console.debug('RICERCA IN CORSO....')
    this.categories = this.allCategories.filter(element => element.name.toLowerCase().includes(this.form.value.parentId.toLowerCase()))
  }else{
    this.categories = this.allCategories;
  }
}

displayFn(option: any): string {
  return option&& option.name ? option.name : '';
}

findParentCat(arrCat,parentId){
  return arrCat.find(element => element.id === parentId)?.name;
}

}
