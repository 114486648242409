import { TimeChallengeDetailComponent } from './time-challenge-detail/time-challenge-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-time-challenge',
  templateUrl: './time-challenge.component.html',
  styleUrls: ['./time-challenge.component.scss']
})
export class TimeChallengeComponent implements OnInit {

  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions: number[] = [2, 5, 10, 15, 50];
  lunghezza: any;
  challenges: any;
  dataSource: MatTableDataSource<any> | null;
  displayedColumns: string[] = ['nome', 'data-pubblicazione', 'pending', 'accepted', 'actions'];

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  environment = environment.baseURL

  constructor(private restService: RestService, public guiService: GuiService, public translate: TranslateService, private dialog: MatDialog, private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.getChallenges()
  }

  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.getChallenges()
  }

  getChallenges() {
    setTimeout(() => this.guiService.showSpinner = true, 0)
    this.dataSource = new MatTableDataSource();
    this.challenges = []
    this.restService.postService('node', '/challenge-data', { page: this.pageNumber, size: this.pageSize }).subscribe((ris) => {
      this.lunghezza = ris.totalItems
      this.challenges = ris["items"];
      console.debug(ris.items)
      this.dataSource.data = this.challenges;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  detail(item, type) {
    let tmpObj = {
      data: item,
      type: type
    }
    this.dialog.open(TimeChallengeDetailComponent, {
      data: tmpObj || null,
      disableClose: true,
      hasBackdrop: true,
      minWidth: '70vw',
      minHeight: '500px'
    })
  }

  copyLink(obj: any, templateRef: any) {
    console.debug('ID TO COPY', obj._id)
    this.clipboard.copy(obj._id)
    let dialogRef = this.dialog.open(templateRef, {
      width: '300px'
    })
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 1300);
    })
  }
}
