<div class="contenitore">
  <div class="content">
    <div class="top-logo">
      <img class="img-top-logo" src="../../../assets/img/demo/logo_checkout.png">
    </div>
    <div class="step">
      <div>
        <div class="number-circle">1</div>
        <h2 class="step-title">
          {{"REGISTER.SELECT_SUB" | translate}}
        </h2>
        <h2 class="discount-title">
          {{pricePromo?.top_msg[translate.currentLang.toLocaleLowerCase()]}}
        </h2>
      </div>
      <div class="choose-plan">
        <div class="plan-box choose-duration" *ngFor="let duration of avaiable_duration">
          <div class="header-plan-duration duration-box" [class.selected]="default_selection.duration == duration" (click)="selectDuration(duration)">
            <p *ngIf="duration == 'H'" class="save-title">
              <img src="../../../assets/img/demo/thunderbolt_gold.svg">
              {{"REGISTER.SAVE_MSG" | translate}}
            </p>
            <p *ngIf="duration != 'H'" class="mt-4">

            </p>
            <h4>{{"REGISTER."+duration | translate}}</h4>
          </div>
        </div>
      </div>
      <div class="choose-plan second-step" *ngIf="default_selection.duration == 'M'">
        <div class="plan-box" *ngFor="let plan of subs_M" >
          <div class="tag-info">
            <h3 *ngIf="plan.type == 'GOLD'">{{"REGISTER.WORKOUT" | translate}}</h3>
            <h3 *ngIf="plan.type == 'DIAMOND'">{{"REGISTER.WORKOUT_MEAL" | translate}}</h3>
          </div>
          <div class="header-plan-duration" [class.selected]="default_selection.type == plan.type" [class.current-plan]="plan.id == guiService.userLogged.userPaymentInfo.subscriptionId" (click)="selectPlan(plan.type)">
            <h4 *ngIf="default_selection.type == plan.type"><img src="../../../assets/img/demo/thunderbolt_white.svg">{{plan.type}}</h4>
            <h4 *ngIf="default_selection.type != plan.type" class="color-black"><img src="../../../assets/img/demo/thunderbolt_black.svg">{{plan.type}}</h4>
            <div class="inside-bg">
              <p *ngIf="plan.type =='GOLD'" class="label-days">{{plan?.trialPeriod && plan?.trialPeriod > 0 ? plan?.trialPeriod + ("REGISTER.SEVEN_DAYS" | translate) : " "}}</p>
              <p *ngIf="plan.type =='DIAMOND'" class="label-days">{{"REGISTER.MOST_COMPLETE" | translate}}</p>
              <h3 class="price" *ngIf="!pricePromo?.normal_price[plan.id] || pricePromo?.normal_price[plan.id] && !pricePromo.dateValid">$ {{plan.price.price}}<span class="price-duration"> /
                {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
                <div class="discounted-price-empty" *ngIf="pricePromo.dateValid"></div>
              </h3>
              <h3 class="price no-bold" *ngIf="pricePromo?.normal_price[plan.id] && pricePromo.dateValid">$ {{pricePromo.normal_price[plan.id]}}<span class="price-duration"> /
                {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
              <span class="bar-on-price" *ngIf="pricePromo.normal_price[plan.id]"></span>
              <div class="discounted-price">$ {{plan.price.price}}
                <span class="price-duration"> / {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
              </div>
              </h3>
              <div class="tag-details">
                <button class="show-details" (click)="showSubDetails(plan)">{{"REGISTER.DISCOVER" | translate}}</button>
              </div>
              <p>{{"REGISTER.CANCEL" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="choose-plan second-step" *ngIf="default_selection.duration == 'Q'">
        <div class="plan-box" *ngFor="let plan of subs_Q">
          <div class="tag-info">
            <h3 *ngIf="plan.type == 'GOLD'">{{"REGISTER.WORKOUT" | translate}}</h3>
            <h3 *ngIf="plan.type == 'DIAMOND'">{{"REGISTER.WORKOUT_MEAL" | translate}}</h3>
          </div>
          <div class="header-plan-duration" [class.selected]="default_selection.type == plan.type" [class.current-plan]="plan.id == guiService.userLogged.userPaymentInfo.subscriptionId" (click)="selectPlan(plan.type)">
            <h4 *ngIf="default_selection.type == plan.type"><img src="../../../assets/img/demo/thunderbolt_white.svg">{{plan.type}}</h4>
            <h4 *ngIf="default_selection.type != plan.type" class="color-black"><img src="../../../assets/img/demo/thunderbolt_black.svg">{{plan.type}}</h4>
            <div class="inside-bg">
              <p *ngIf="plan.type =='GOLD'" class="label-days">{{plan?.trialPeriod && plan?.trialPeriod > 0 ? plan?.trialPeriod + ("REGISTER.SEVEN_DAYS" | translate) : " "}}</p>
              <p *ngIf="plan.type =='DIAMOND'" class="label-days">{{"REGISTER.MOST_COMPLETE" | translate}}</p>
              <h3 class="price" *ngIf="!pricePromo?.normal_price[plan.id] || pricePromo?.normal_price[plan.id] && !pricePromo.dateValid">$ {{plan.price.price}}<span class="price-duration"> /
                {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
                <div class="discounted-price-empty" *ngIf="pricePromo.dateValid"></div>
              </h3>
              <h3 class="price no-bold" *ngIf="pricePromo?.normal_price[plan.id] && pricePromo.dateValid">$ {{pricePromo.normal_price[plan.id]}}<span class="price-duration"> /
                {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
              <span class="bar-on-price" *ngIf="pricePromo.normal_price[plan.id]"></span>
              <div class="discounted-price">$ {{plan.price.price}}
                <span class="price-duration"> / {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
              </div>
              </h3>
              <div class="tag-details">
                <button class="show-details" (click)="showSubDetails(plan)">{{"REGISTER.DISCOVER" | translate}}</button>
              </div>
              <p>{{"REGISTER.CANCEL" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="choose-plan second-step" *ngIf="default_selection.duration == 'H'">
        <div class="plan-box" *ngFor="let plan of subs_H">
          <div class="tag-info">
            <h3 *ngIf="plan.type == 'GOLD'">{{"REGISTER.WORKOUT" | translate}}</h3>
            <h3 *ngIf="plan.type == 'DIAMOND'">{{"REGISTER.WORKOUT_MEAL" | translate}}</h3>
          </div>
          <div class="header-plan-duration" [class.selected]="default_selection.type == plan.type" [class.current-plan]="plan.id == guiService.userLogged.userPaymentInfo.subscriptionId" (click)="selectPlan(plan.type)">
            <h4 *ngIf="default_selection.type == plan.type"><img src="../../../assets/img/demo/thunderbolt_white.svg">{{plan.type}}</h4>
            <h4 *ngIf="default_selection.type != plan.type" class="color-black"><img src="../../../assets/img/demo/thunderbolt_black.svg">{{plan.type}}</h4>
            <div class="inside-bg">
              <p *ngIf="plan.type =='GOLD'" class="label-days">{{plan?.trialPeriod && plan?.trialPeriod > 0 ? plan?.trialPeriod + ("REGISTER.SEVEN_DAYS" | translate) : " "}}</p>
              <p *ngIf="plan.type =='DIAMOND'" class="label-days">{{"REGISTER.MOST_COMPLETE" | translate}}</p>
              <h3 class="price" *ngIf="!pricePromo?.normal_price[plan.id] || pricePromo?.normal_price[plan.id] && !pricePromo.dateValid">$ {{plan.price.price}}<span class="price-duration"> /
                {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
                <div class="discounted-price-empty" *ngIf="pricePromo.dateValid"></div>
              </h3>
              <h3 class="price no-bold" *ngIf="pricePromo?.normal_price[plan.id] && pricePromo.dateValid">$ {{pricePromo.normal_price[plan.id]}}<span class="price-duration"> /
                {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
              <span class="bar-on-price" *ngIf="pricePromo.normal_price[plan.id]"></span>
              <div class="discounted-price">$ {{plan.price.price}}
                <span class="price-duration"> / {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
              </div>
              </h3>
              <div class="tag-details">
                <button class="show-details" (click)="showSubDetails(plan)">{{"REGISTER.DISCOVER" | translate}}</button>
              </div>
              <p>{{"REGISTER.CANCEL" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="choose-plan second-step" *ngIf="default_selection.duration == 'Y'">
        <div class="plan-box" *ngFor="let plan of subs_Y">
          <div class="tag-info">
            <h3 *ngIf="plan.type == 'GOLD'">{{"REGISTER.WORKOUT" | translate}}</h3>
            <h3 *ngIf="plan.type == 'DIAMOND'">{{"REGISTER.WORKOUT_MEAL" | translate}}</h3>
          </div>
          <div class="header-plan-duration" [class.selected]="default_selection.type == plan.type" [class.current-plan]="plan.id == guiService.userLogged.userPaymentInfo.subscriptionId" (click)="selectPlan(plan.type)">
            <h4 *ngIf="default_selection.type == plan.type"><img src="../../../assets/img/demo/thunderbolt_white.svg">{{plan.type}}</h4>
            <h4 *ngIf="default_selection.type != plan.type" class="color-black"><img src="../../../assets/img/demo/thunderbolt_black.svg">{{plan.type}}</h4>
            <div class="inside-bg">
              <p *ngIf="plan.type =='GOLD'" class="label-days">{{plan?.trialPeriod && plan?.trialPeriod > 0 ? plan?.trialPeriod + ("REGISTER.SEVEN_DAYS" | translate) : " "}}</p>
              <p *ngIf="plan.type =='DIAMOND'" class="label-days">{{"REGISTER.MOST_COMPLETE" | translate}}</p>
              <h3 class="price" *ngIf="!pricePromo?.normal_price[plan.id] || pricePromo?.normal_price[plan.id] && !pricePromo.dateValid">$ {{plan.price.price}}<span class="price-duration"> /
                {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
                <div class="discounted-price-empty" *ngIf="pricePromo.dateValid"></div>
              </h3>
              <h3 class="price no-bold" *ngIf="pricePromo?.normal_price[plan.id] && pricePromo.dateValid">$ {{pricePromo.normal_price[plan.id]}}<span class="price-duration"> /
                {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
              <span class="bar-on-price" *ngIf="pricePromo.normal_price[plan.id]"></span>
              <div class="discounted-price">$ {{plan.price.price}}
                <span class="price-duration"> / {{"REGISTER.PERIOD_"+plan.duration | translate}}</span>
              </div>
              </h3>
              <div class="tag-details">
                <button class="show-details" (click)="showSubDetails(plan)">{{"REGISTER.DISCOVER" | translate}}</button>
              </div>
              <p>{{"REGISTER.CANCEL" | translate}}</p>
            </div>
        </div>
      </div>
    </div>

    <div class="step">
      <div class="mb-4">
        <div class="number-circle">2</div>
        <h2 class="step-title">
          {{"AUTH_UPGRADE.CONFIRM_UPG" | translate}}
        </h2>
      </div>
      <div class="step-box mb-2 flex flex-col sm:flex-row gap-2 sm:gap-6" [formGroup]="verticalAccountFormGroup">
        <div class="flex-auto">
          <label>E-Mail</label>
          <input formControlName="email" matInput required>
          <mat-error *ngIf="verticalAccountFormGroup.get('email').hasError('required')">{{"LOGIN.EMAIL_MSG" | translate}}</mat-error>
        </div>
        <div class="flex-auto">
          <label>Password</label>
          <input formControlName="password" type="password" required>
          <mat-error *ngIf="verticalAccountFormGroup.get('password').hasError('required')">{{"LOGIN.PASS_MSG" | translate}}</mat-error>
        </div>
      </div>
    </div>
    <div class="btn-div">
      <button (click)="login()" [disabled]="verticalAccountFormGroup.invalid || showBtnSpinner == true" [class.disabled]="verticalAccountFormGroup.invalid || showBtnSpinner == true" class="btn-create">
                  <span *ngIf="showBtnSpinner == false">{{"AUTH_UPGRADE.CONFIRM_UPG" | translate}}</span>
                  <span *ngIf="showBtnSpinner == true">
                    <mat-icon><mat-spinner color="warn" diameter="20">
                    </mat-spinner></mat-icon>
                  </span>
          </button>
    </div>
  </div>
</div>
