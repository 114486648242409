<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span>{{"CUSTOMERS" | translate}}</span>
    </h2>
    <div class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        placeholder="{{'SUB_TABLE.S_EMAIL' | translate}}" type="search" />
    </div>
    <div class="rounded-full border px-4 max-w-[230px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrlName" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        placeholder="{{'SUB_TABLE.S_NAME' | translate}}" type="search" />
    </div>
    <div class="rounded-full border px-4 max-w-[230px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrlLastName" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        placeholder="{{'SUB_TABLE.S_LASTNAME' | translate}}" type="search" />
    </div>
    <button class="ml-4 flex-none ltr:mr-4 ltr:border-r" color="primary" mat-mini-fab type="button"
      (click)="search();resetPage()">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload"
      type="button">
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>

    <span class="flex-1"></span>

  </div>

  <div class="bg-app-bar px-6 border-b sticky left-0 flex items-center bg2">
    <div class="flex items-center filter-bar">
      <nav class="vex-tabs border-0 flex-auto cerca" mat-tab-nav-bar>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.REG" | translate}}</mat-label>

          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="M/DD/YYYY" [formControl]="searchCtrlReg_from">
            <input matEndDate placeholder="M/DD/YYYY" [formControl]="searchCtrlReg_to">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.TYPE" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlType">
            <mat-option [value]="'GOLD'">GOLD</mat-option>
            <mat-option [value]="'DIAMOND'">DIAMOND</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.PAY" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlPay">
            <mat-option [value]="'ACTIVE'">{{"STATUS.PAYED" | translate}}</mat-option>
            <mat-option [value]="'PAUSED'">{{"STATUS.PAUSED" | translate}}</mat-option>
            <mat-option [value]="'SUSPENDED'">{{"STATUS.NOT_PAYED" | translate}}</mat-option>
            <mat-option [value]="'CUST_CANCELED'">{{"STATUS.CANCELED" | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.EXP" | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="picker2">
            <input matStartDate placeholder="M/DD/YYYY" [formControl]="searchCtrlExp_from">
            <input matEndDate placeholder="M/DD/YYYY" [formControl]="searchCtrlExp_to">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.S_PROMO" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlPromo">
            <mat-option [value]="promo.code" *ngFor="let promo of promotions">{{promo.code}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.REQUEST" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlRequest">
            <mat-option [value]="'PAUSED'">{{"SUB_TABLE.PAUSE" | translate}}</mat-option>
            <mat-option [value]="'CUST_CANCELED'">{{"SUB_TABLE.CANC" | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </nav>

    </div>
  </div>

  <div class="container" style="display: flex; padding: 0;">
    <div class="users-list">
      <table mat-table [dataSource]="dataSource" *ngIf="users" matSort>
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <mat-checkbox class="checkbox" (change)="checkUser(user)" [checked]="checkIfChecked(user.user.id)" [disabled]="user.masterData.functionalityPromotions && user.masterData.functionalityPromotions.length > 0"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="utente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          (click)="searchAltFlag == false ? sortParam('email', asc == true ? false : true) : search('email', asc == true ? false : true)">
          {{"SUB_TABLE.USER" | translate}}</th>
        <td mat-cell *matCellDef="let user"><span class="name_lastname"> {{ user.masterData.name }} {{
            user.masterData.lastName }}</span><br><span class="mail">{{ user.user.email }}</span> </td>
      </ng-container>
      <ng-container matColumnDef="data-iscrizione">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          (click)="searchAltFlag == false ? sortParam('registrationDate', asc == true ? false : true) : search('registrationDate', asc == true ? false : true)">
          {{"CUST_TABLE.DATE" | translate}}</th>
        <td mat-cell *matCellDef="let user">{{ user.masterData.registrationDate | date: 'shortDate'}}</td>
      </ng-container>
      <ng-container matColumnDef="ondemand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> On Demand </th>
        <td mat-cell *matCellDef="let user">
          <span *ngIf="user.masterData.functionalityPromotions ? checkOndemandPromotion(user.masterData.functionalityPromotions, user.userPaymentInfo && user.userPaymentInfo.subscriptionId ? user.userPaymentInfo.subscriptionId : null) : null">
            <mat-icon svgIcon="mat:check_circle"></mat-icon>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    </div>

    <div class="selected-users-list" *ngIf="users">
      <div class="header">
        {{"WELLNESS.ASSIGN_PAGE.HEADER_TITLE" | translate}}
        <button mat-raised-button (click)="assignPromotion()" *ngIf="checkedUsers.length > 0">{{"WELLNESS.ASSIGN_PAGE.BTN_CONFIRM" | translate}}</button>
      </div>
      <div *ngFor="let user of checkedUsers">
        <div class="selected-user">
          <div>
            <h3  class="name_lastname">{{user.user.email}}</h3>
            <span class="mail">{{ user.user.email }}</span>
          </div>
          <mat-icon svgIcon="mat:delete" (click)="checkUser(user)"></mat-icon>
        </div>
      </div>
    </div>
  </div>

  <mat-paginator [style.display]="users ? 'flex' : 'none'" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza"
    (page)="onPageChanged($event)" class="sticky left-0" ></mat-paginator>

</div>
