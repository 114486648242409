<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span>{{"CATEGORY" | translate}}</span>
    </h2>
    <button class="flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button"
      (click)="search()">
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>

    <button class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab
      [matTooltip]="'WELLNESS.EDIT_MODAL.NEW' | translate" type="button" (click)="createCategory(null,true)">
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>

  <div class="main-container">
    <div class="side-folders">
      <div>
        <mat-form-field class="flex-auto" style="width: 100%;">
          <mat-label>{{"WELLNESS.LANG" | translate}}</mat-label>
          <mat-select matInput [(ngModel)]="lang_to_show" (selectionChange)="search()">
            <mat-option [value]="item" *ngFor="let item of translate.getLangs()">{{"WELLNESS."+item.toUpperCase() |
              translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node;" >
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node['name_'+lang_to_show]">
            <mat-icon svgIcon="mat:folder_open"
              *ngIf="treeControl.isExpanded(node) && treeControl.isExpandable(node)"></mat-icon>
            <mat-icon svgIcon="mat:folder"
              *ngIf="!treeControl.isExpanded(node)&& treeControl.isExpandable(node)"></mat-icon>
          </button>
          <span (click)="checkSelected(node)" class="node-name">{{node['name_'+lang_to_show]}}</span>
          <span class="circle-add-btn" *ngIf="treeControl.isExpandable(node)" (click)="createCategory(node)">
            <mat-icon svgIcon="mat:add"></mat-icon>
          </span>
        </mat-tree-node>
      </mat-tree>
    </div>
    <div class="mid-divider">

    </div>
    <div class="view-side">
      <div class="top-part" *ngIf="selectedNode">
        <h1>{{selectedNode['name_'+lang_to_show]}}</h1>
        <div class="d-flex">
          <button class="btn-controls" style="min-width: unset;" *ngIf="selectedNode.macroAreaId" (click)="copyMacroId(selectedNode.macroAreaId, templateClip)">
            <mat-icon svgIcon="mat:content_copy" matTooltip="copy id"></mat-icon>
          </button>
          <button class="btn-controls" (click)="reorderVideos()" *ngIf="saveVideoOrder">
            {{"WELLNESS.SAVE_VID_ORDER" | translate}}
          </button>
          <button class="btn-controls" (click)="editCategory(selectedNode)">
            {{"GLOBAL.EDIT" | translate}}
          </button>
          <button class="btn-controls" (click)="deleteCategory(selectedNode.id)">
            {{"GLOBAL.DELETE" | translate}}
          </button>
          <button class="btn-controls" (click)="createVideo()">
            <span>{{"WELLNESS.ADD" | translate}}</span>
            <mat-icon svgIcon="mat:add"></mat-icon>
          </button>
        </div>
      </div>
      <div class="main-part" *ngIf="selectedNode && selectedNode.videos">
        <div class="full-card" *ngFor="let item of selectedNode.videos; let indice = index">
          <div>
            <div class="top-card">
              <mat-icon svgIcon="mat:lock" *ngIf="item.videoBlocked"></mat-icon>
              <mat-icon svgIcon="mat:check_circle" *ngIf="!item.videoBlocked"></mat-icon>
              <h1>{{item.i18nName[lang_to_show.toUpperCase()]}}</h1>
              <mat-icon svgIcon="mat:edit" (click)="editVideo(indice)"></mat-icon>
              <mat-icon svgIcon="mat:delete" (click)="deleteVideo(indice)"></mat-icon>
            </div>
            <div class="main-card">
              <p>{{item.i18nDescription[lang_to_show.toUpperCase()]}}</p>
            </div>
          </div>

          <div class="action-card">
            <img *ngIf="item.i18nvideoId && item.i18nvideoId[lang_to_show.toUpperCase()]" (click)="openVidyardVideoEdit(item.i18nvideoId[lang_to_show.toUpperCase()])"
              src="https://play.vidyard.com/{{item.i18nvideoId[lang_to_show.toUpperCase()]}}.jpg" alt="">

              <img *ngIf="item.i18nvideoIds && item.i18nvideoIds[lang_to_show.toUpperCase()]" (click)="openVidyardVideoEdit(item.i18nvideoIds[lang_to_show.toUpperCase()])"
              src="https://play.vidyard.com/{{item.i18nvideoIds[lang_to_show.toUpperCase()]}}.jpg" alt="">
            <div class="arrow-icons-zone">
              <span class="icon-card" (click)="moveVideo('up', indice)" *ngIf="indice > 0">
                <mat-icon svgIcon="mat:expand_less"></mat-icon>
              </span>
              <span class="icon-card disabled" *ngIf="indice === 0">
                <mat-icon svgIcon="mat:expand_less"></mat-icon>
              </span>
              <span class="icon-card disabled" *ngIf="selectedNode.videos.length-1 === indice">
                <mat-icon svgIcon="mat:expand_more"></mat-icon>
              </span>
              <span class="icon-card" (click)="moveVideo('down', indice)" *ngIf="selectedNode.videos.length-1 > indice">
                <mat-icon svgIcon="mat:expand_more"></mat-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateClip>
  <div class="dialog-msg-div">
    <h1 *ngIf="translate.currentLang == 'it'">Copiato negli appunti</h1>
    <h1 *ngIf="translate.currentLang == 'en'">Copied to clipboard</h1>
  </div>
 </ng-template>
