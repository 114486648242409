import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';
import { RegSubInfoComponent } from '../register/reg-sub-info/reg-sub-info.component';
import { ConfirmRenewModalComponent } from './confirm-renew-modal/confirm-renew-modal.component';

@Component({
  selector: 'vex-renew-plan',
  templateUrl: './renew-plan.component.html',
  styleUrls: ['./renew-plan.component.scss']
})
export class RenewPlanComponent implements OnInit {
  verticalPlanFormGroup: UntypedFormGroup;
  verticalAccountFormGroup: UntypedFormGroup;
  selectedProgram: any;
  subs:any;
  showBtnSpinner: any;
  platform: any;

  subs_M: any = [];
  subs_Q: any = [];
  subs_H: any = [];
  subs_Y: any = [];
  avaiable_duration: any = []
  default_selection: any = {
    duration: 'M',
    type: 'GOLD'
  }

  promos = [
    {
      code: 'GENNAIO24',
      identifier: ["633adc3969be1503611544ca"]
    },
    {
      code: 'NEW24',
      identifier: ["633adc3969be1503611544ca"]
    },
    {
      code: 'COMEBACK24',
      identifier: ["633adc3969be1503611544ca"]
    }
  ];
  isPromoValid: boolean = false;
  oldAccount: any;

  constructor(
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private restService: RestService,
    private router: Router,
    public guiService: GuiService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.platform = this.route.snapshot.paramMap.get('device')
    this.getSubs()
    this.getMockPromoCodes();
    this.showBtnSpinner = false

    this.verticalAccountFormGroup = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
      coupon: []
    });
    if(!this.guiService.userLogged){
      this.router.navigate(['auth/login']);
    }
  }

  getSubs(){
    this.restService.getService('subscriptions', 'actives').subscribe((ris)=>{
      this.subs = []
      for(let i = 0; i< ris.length; i++){
        let sub = ris[i]
        if(sub.duration == 'M'){
          let now = moment()
          if(sub.endDate && moment(sub.endDate,'x').isAfter(now)){
            this.subs_M.push(sub)
            this.avaiable_duration.includes('M') ? null : this.avaiable_duration.push('M')
          }
        }else if(sub.duration == 'Q'){
          let now = moment()
          if(sub.endDate && moment(sub.endDate,'x').isAfter(now)){
            this.subs_Q.push(sub)
            this.avaiable_duration.includes('Q') ? null : this.avaiable_duration.push('Q')
          }
        } else if(sub.duration == 'H'){
          let now = moment()
          if(sub.endDate && moment(sub.endDate,'x').isAfter(now)){
            this.subs_H.push(sub)
            this.avaiable_duration.includes('H') ? null : this.avaiable_duration.push('H')
          }
        } else if(sub.duration == 'Y'){
          let now = moment()
          if(sub.endDate && moment(sub.endDate,'x').isAfter(now)){
            this.subs_Y.push(sub)
            this.avaiable_duration.includes('Y') ? null : this.avaiable_duration.push('Y')
          }
        }
      }
      this.subs_M.reverse()
      this.subs_Q.reverse()
      this.subs_H.reverse()
      this.subs_Y.reverse()

      console.debug('M',this.subs_M)
      console.debug('Q',this.subs_Q)
      console.debug('H',this.subs_H)
      console.debug('Y',this.subs_Y)
      console.debug(this.avaiable_duration)
    })
  }

  login(){
    let login = {
      email: this.verticalAccountFormGroup.value.email,
      password: this.verticalAccountFormGroup.value.password
    }
    this.showBtnSpinner = true
    this.restService.doLogin('',login).subscribe({
      next: (ris)=>{
        this.restService.tknSubj.next(ris.body["token"])
        console.debug("LOGIN RES",ris);
        let tkn = ris.body["token"]
        sessionStorage.removeItem("tkn");
        sessionStorage.setItem("tkn", tkn);
        this.getCurrentInfo(tkn)
      },error: (e)=>{
        this.guiService.openSnackBar(this.translate.instant("MSG.LOGIN_FAIL"), this.translate.instant("MSG.CLOSE"), 'error')
        this.showBtnSpinner = false
      }

    })
  }

  getCurrentInfo(tkn){
    this.restService.getService('current_user', '').subscribe((response)=>{

      this.dialog.open(ConfirmRenewModalComponent,{
        disableClose: true,
        hasBackdrop: true,
        width: '90vw',
        maxWidth: '900px'
      }).afterClosed().subscribe((confirm) => {
        if(confirm == 'done'){
          console.debug('renew confirmed')
          this.oldAccount = response;
          this.deleteAccount()
        }
      })
    })
  }

  deleteAccount() {
    this.restService.deleteService('users', '/' + this.oldAccount.user.id, {}).subscribe((ris) => {
      console.debug('ACCOUNT ELIMINATO')
      sessionStorage.removeItem("tkn");
      this.submitOldAccount();
    })
  }

  submitOldAccount() {
    this.findPlanObj()
    let newAccount = {
      subscriptionId: this.selectedProgram.id,
      name: this.oldAccount.masterData.name,
      lastName: this.oldAccount.masterData.lastName,
      email: this.oldAccount.user.email.toLowerCase(),
      birthDate: moment(this.oldAccount.masterData.birthDate).format('YYYY-DD-MM'),
      country: this.oldAccount.masterData.country,
      password: this.verticalAccountFormGroup.value.password,
      paymentType: 'CHARGEBEE',
      newsLetterRegistration: true,
      promotionCodesToBeAdded: this.verticalAccountFormGroup.value.coupon != null && this.verticalAccountFormGroup.value.coupon != '' && this.isPromoValid ? [this.verticalAccountFormGroup.value.coupon] : []
    }
    this.guiService.showSpinner = true

    this.restService.postService('register', '', newAccount).subscribe({
      next: (ris) => {
        let login = {
          email: this.oldAccount.user.email.toLowerCase(),
          password: this.verticalAccountFormGroup.value.password
        }
        let sub = {
          subscriptionId: this.selectedProgram.id
        }

        this.restService.doLogin('', login).subscribe((res) => {
          sessionStorage.setItem("tkn", res.body["token"])
          this.restService.postService('chargebee', '/create-subscription', sub).subscribe({
            next: (response) => {
              console.debug(response)
              this.router.navigate(['auth/register-confirm'])
              this.guiService.showSpinner = false
            }, error: (err) => {
            }

          })
        })

      },
      error: (err) => {
        if (err.status == 400) {
          let msg = err.error && err.error.errors && err.error.errors[0] ? err.error.errors[0] : "OOPS. Please contact support"

          if (err.error && err.error.errors && err.error.errors[0] == 'Email already in use.') {
          } else {
          }
        }
        this.guiService.showSpinner = false
      }
    });

  }

  selectPlan(type: any){
    this.default_selection.type = type
    console.debug(this.default_selection.type)
  }

  selectDuration(duration:any){
    this.default_selection.duration = duration
    console.debug(this.default_selection.duration)
  }

  findPlanObj(){
    let obj
    if(this.default_selection.duration == 'M'){
      if(this.default_selection.type == 'GOLD'){
        obj = this.subs_M.find(o => o.type === 'GOLD');
      }else if(this.default_selection.type == 'DIAMOND'){
        obj = this.subs_M.find(o => o.type === 'DIAMOND');
      }
    }
    if(this.default_selection.duration == 'Q'){
      if(this.default_selection.type == 'GOLD'){
        obj = this.subs_Q.find(o => o.type === 'GOLD');
      }else if(this.default_selection.type == 'DIAMOND'){
        obj = this.subs_Q.find(o => o.type === 'DIAMOND');
      }
    }
    if(this.default_selection.duration == 'H'){
      if(this.default_selection.type == 'GOLD'){
        obj = this.subs_H.find(o => o.type === 'GOLD');
      }else if(this.default_selection.type == 'DIAMOND'){
        obj = this.subs_H.find(o => o.type === 'DIAMOND');
      }
    }
    if(this.default_selection.duration == 'Y'){
      if(this.default_selection.type == 'GOLD'){
        obj = this.subs_Y.find(o => o.type === 'GOLD');
      }else if(this.default_selection.type == 'DIAMOND'){
        obj = this.subs_Y.find(o => o.type === 'DIAMOND');
      }
    }

    return this.selectedProgram = obj
  }

  showSubDetails(sub:any){
    console.debug('dettaglio',sub)
    this.dialog.open(RegSubInfoComponent,{
      data: sub,
      panelClass: "reg-sub-info-modalbox",
      width: '90vw',
      maxWidth: '90vw'
    })
  }

  checkPromo(templateRef:any){
    this.findPlanObj()
    if(this.verticalAccountFormGroup.value.coupon && this.verticalAccountFormGroup.value.coupon.length > 3){
      this.restService.getService('promotions', 'validate/' + this.verticalAccountFormGroup.value.coupon+'/'+this.selectedProgram.id).subscribe((ris)=>{
       if(this.promos.find(x => x.code === this.verticalAccountFormGroup.value.coupon) && ris == true){
        console.debug('IF',this.selectedProgram)
        if(this.promos.find(x => x.code === this.verticalAccountFormGroup.value.coupon).identifier.includes(this.selectedProgram.id) === true){
          this.isPromoValid = true
        }else{
          this.isPromoValid = false
          let dialogRef = this.dialog.open(templateRef,{
            width:'450px'
          })
          dialogRef.afterOpened().subscribe(_ =>{
            setTimeout(() => {
              dialogRef.close();
            }, 2500);
          })
        }
       }else{
        console.debug('ELSE')
        this.isPromoValid = ris
       }
      })
    }
  }

  getMockPromoCodes(){
    this.restService.getService('mock','promos').subscribe((ris)=>{
      this.promos = ris
    })
  }

}
