import { MatDialog } from '@angular/material/dialog';
import { RestService } from 'src/app/services/rest.service';
import { UserComponent } from './../user.component';
import { GuiService } from 'src/app/services/gui.service';
import { toDate } from 'date-fns';
import { Component, Inject, OnInit } from '@angular/core';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCircle, faCheck, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import { ChangeSubstatusModalComponent } from 'src/app/main/user/sub/change-substatus-modal/change-substatus-modal.component';

@Component({
  selector: 'vex-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class UserEditComponent implements OnInit {

  icon = {
    "faCircle": faCircle,
    "faCheck": faCheck,
    "faExternal": faExternalLinkAlt
  };
  mnt = moment

  form: UntypedFormGroup
  flag: any
  programInfo: any;
  lastWorkoutName: any = 'N/A'

  user: any;

  subsList: any

  sub_type2: any;

  constructor(private fb: UntypedFormBuilder, public translate: TranslateService, public guiService: GuiService, private dialogRef: MatDialogRef<UserComponent>, @Inject(MAT_DIALOG_DATA) public item: any['item'], public restService: RestService, public dialog: MatDialog) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.user = this.item;
    if(this.user.lastWorkoutProgram != null && this.user.lastWorkoutProgram.exerciseId != null){
    let lastW = this.user.lastWorkoutProgram.exerciseId.split("::");
    this.lastWorkoutName = lastW[1]
    }
    this.getProgram()
    this.form = this.fb.group({
      phonenumber:[null],
      registration: [{value:null,disabled: true}, Validators.required],
      email: [null, Validators.required],
      birthday: [null, Validators.required],
      // sub_status: [null, Validators.required],
      pay_status: [null, Validators.required],
      questionario: [null, Validators.required],
      follow_up: [null, Validators.required],
      upload_dieta: [null, Validators.required],
      password: [null],
      confirmpassword: [null]
    })

    console.debug(this.user)
    this.form.controls['phonenumber'].setValue(this.user.masterData.phone);
    this.form.controls['registration'].setValue(formatDate(this.user.masterData.registrationDate,'yyyy-MM-dd','en'));
    this.form.controls['email'].setValue(this.user.user.email);
    this.form.controls['birthday'].setValue(formatDate(this.user.masterData.birthDate,'yyyy-MM-dd','en'));
    // this.form.controls['sub_status'].setValue(this.user.userPaymentInfo != null && this.user.userPaymentInfo.subscriptionStatus != null ? this.user.userPaymentInfo.subscriptionStatus  : null);
    this.form.controls['pay_status'].setValue(this.user.userPaymentInfo != null && this.user.userPaymentInfo.subscriptionPaymentStatus != null ? this.user.userPaymentInfo.subscriptionPaymentStatus  : null);
    this.form.controls['questionario'].setValue('');
    this.form.controls['follow_up'].setValue('');
    this.form.controls['password'].setValue('');
    this.form.controls['confirmpassword'].setValue('');
    this.form.controls['upload_dieta'].setValue('');


    setTimeout(()=>this.guiService.showSpinner = false,3000)
  }

  getProgram(){
    if(this.item.lastWorkoutProgram != null && this.item.lastWorkoutProgram.workoutProgramId !== 'string'){
    this.restService.getService('workout_programs', this.item.lastWorkoutProgram.workoutProgramId).subscribe((ris)=>{
      console.debug(ris)
      this.programInfo = ris;
      this.getSubStatus()
    })
    }else{
      this.getSubStatus()
    }
  }


  getSubStatus(){
    this.restService.getService('bos','/subscription-status').subscribe((ris)=>{
      this.sub_type2 = ris
    })
  }

  checkDietUpload(diet,survey){
    return moment(diet).isSameOrAfter(survey)
  }

  submit(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    let editUser = {};

    this.form.value.phonenumber!=this.user.masterData.phone ? editUser["phone"] = this.form.value.phonenumber : null;
    this.form.value.email!=this.user.masterData.email ? editUser["email"] = this.form.value.email : null;
    this.form.value.birthday!=this.user.masterData.birthDate ? editUser["birthDate"] = this.form.value.birthday : null;

    if(this.form.value.password!="") {
      this.form.value.password === this.form.value.confirmpassword ? editUser["password"] = this.form.value.password : null;
    }

    // this.form.value.promocode != null ? editUser["promotionCodesToBeAdded"] = [this.form.value.promocode] : null

    console.debug("Edit User:", editUser);
    this.restService.putService('users', '/'+ this.item.user.id, editUser).subscribe((ris)=>{
      this.dialogRef.close('done');
      this.guiService.showSpinner = false;
      this.resetUserSessionToken();
    })

  }

  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }

  chargeBeePortal(userId){
    this.restService.getService('chargebee','/portal-session/'+userId).subscribe((res)=>{
      window.open(res.chargeBeePortalSession,'_blank')
    })
  }

  goToChargeBee(subId){
    window.open('https://itsdesifit-second-test.chargebee.com/d/subscriptions/'+subId,'_blank')
  }

  resetUserSessionToken(){
    this.restService.deleteService('users','/remove-user-session-token/'+this.item.user.id,{}).subscribe((ris)=>{
      this.guiService.openSnackBar(this.item.user.email +' '+ this.translate.instant('GLOBAL.EDIT_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
    })
  }

}
