import { RestService } from './../../../services/rest.service';
import { GuiService } from '../../../services/gui.service';
import { Component, OnInit } from '@angular/core';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { toDate } from 'date-fns';
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '@angular/common';

@Component({
  selector: 'vex-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class ProfileComponent implements OnInit {
  user: any;
  icon = {
    "faBirthday": faBirthdayCake
  }
  form: UntypedFormGroup
  programInfo: any;
  lastWorkoutName:any = 'N/A'

  constructor(public guiService: GuiService, public translate: TranslateService, private fb: UntypedFormBuilder, public restService: RestService) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = false,0)
    this.user = this.guiService.userLogged
    if(this.user.lastWorkoutProgram.exerciseId != null){
    let lastW = this.user.lastWorkoutProgram.exerciseId.split("::");
    this.lastWorkoutName = lastW[1]
    }
    this.getProgram()
    this.form = this.fb.group({
      name: [null, Validators.required],
      lastname: [null],
      phonenumber:[null],
      registration: [null, Validators.required],
      email: [null],
      birthday: [null, Validators.required]
    })
    this.form.controls['name'].setValue(this.user.masterData.name);
    this.form.controls['lastname'].setValue(this.user.masterData.lastName);
    this.form.controls['phonenumber'].setValue(this.user.masterData.phone);
    this.form.controls['registration'].setValue(formatDate(this.user.masterData.registrationDate,'yyyy-MM-dd','en'));
    this.form.controls['email'].setValue(this.user.user.email);
    this.form.controls['birthday'].setValue(formatDate(this.user.masterData.birthDate,'yyyy-MM-dd','en'));
  }

  getProgram(){
    this.restService.getService('workout_programs', this.user.lastWorkoutProgram.workoutProgramId).subscribe((ris)=>{
      console.debug(ris)
      this.programInfo = ris;

    })
  }

  submit(){
    let editUser = {
      name: this.form.value.name,
      lastname: this.form.value.lastname,
      phone: this.form.value.phonenumber,
      email: this.form.value.email,
      birthDate: this.form.value.birthday
    }
    this.restService.putService('users','', editUser).subscribe((ris)=>{
    this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), 'ok!')
    })

  }
}
