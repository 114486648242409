import { TimeChallengeComponent } from './admin/challenge/time-challenge/time-challenge.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { RegisterComponent } from './auth/register/register.component';
import { PaypalButtonComponent } from './auth/register/paypal-button/paypal-button.component';
import { FacebookComponent } from './main/facebook/facebook.component';
import { PaymentRejectedComponent } from './auth/payment-rejected/payment-rejected.component';
import { AdminResetPasswordComponent } from './admin/administrator/admin-reset-password/admin-reset-password.component';
import { NutriResetPasswordComponent } from './nutritionist/nutri/nutri-reset-password/nutri-reset-password.component';
import { UpgradePlanComponent } from './auth/upgrade-plan/upgrade-plan.component';
import { NutriProfileComponent } from './nutritionist/nutri/nutri-profile/nutri-profile.component';
import { NutriComponent } from './nutritionist/nutri/nutri.component';
import { CustomerListComponent } from './nutritionist/customer-list/customer-list.component';
import { ResetPswComponent } from './auth/reset-psw/reset-psw.component';
import { ResetPasswordComponent } from './main/user/reset-password/reset-password.component';
import { WorkoutExeComponent } from './main/workout-exe/workout-exe.component';
import { UserWorkoutComponent } from './main/user-workout/user-workout.component';
import { AuthGuard } from './services/auth.guard';
import { ChallengeComponent } from './admin/challenge/challenge.component';
import { UserChallengeComponent } from './main/user-challenge/user-challenge.component';
import { UserNutritionComponent } from './main/user-nutrition/user-nutrition.component';
import { AdminNotificationsComponent } from './admin/admin-notifications/admin-notifications.component';
import { AdminProfileComponent } from './admin/administrator/admin-profile/admin-profile.component';
import { AdministratorComponent } from './admin/administrator/administrator.component';
import { NotificationsComponent } from './main/notifications/notifications.component';
import { NutritionComponent } from './admin/nutrition/nutrition.component';
import { SubComponent } from './main/user/sub/sub.component';
import { UserProfileComponent } from './main/user/user-profile.component';
import { ProfileComponent } from './main/user/profile/profile.component';
import { HomeComponent } from './main/home/home.component';
import { InfoComponent } from './main/info/info.component';
import { WorkoutComponent } from './admin/workout/workout.component';
import { ProgramComponent } from './admin/program/program.component';
import { ExerciseComponent } from './admin/exercise/exercise.component';
import { PromotionsComponent } from './admin/promotion/promotions.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { SubscriptionComponent } from './admin/subscription/subscription.component';
import { UserComponent } from './admin/user/user.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { ConsoleComponent } from "./admin/console/console.component";
import { UserProgramComponent } from './main/user-program/user-program.component';
import { AppSelectionModalComponent } from './auth/register/app-selection-modal/app-selection-modal.component';
import { RegisterConfirmComponent } from './auth/register-confirm/register-confirm.component';
import { CategorieComponent } from './admin/categorie/categorie.component';
import { AssignOndemandComponent } from './admin/assign-ondemand/assign-ondemand.component';
import { OnDemandComponent } from './main/on-demand/on-demand.component';
import { OnDemandVideoListComponent } from './main/on-demand/on-demand-video-list/on-demand-video-list.component';
import { OnDemandVideoDetailComponent } from './main/on-demand/on-demand-video-detail/on-demand-video-detail.component';
import { RenewPlanComponent } from './auth/renew-plan/renew-plan.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'prefix' },
  {
    path: 'auth',
    children: [
      {
        path: 'welcome',
        loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
      },
      {
        path: 'login',
        loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'login/:redirectto',
        loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'register/:device',
        component: RegisterComponent
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'users/reset/:email/:token',
        component: ResetPswComponent
      },
      {
        path: 'upgrade-plan',
        component: UpgradePlanComponent
      },
      {
        path: 'payment-rejected',
        component: PaymentRejectedComponent
      },
      {
        path: 'register-confirm',
        component: RegisterConfirmComponent
      },
      {
        path: 'application',
        component: AppSelectionModalComponent
      },
      {
        path: 'renew-plan',
        component: RenewPlanComponent
      },
      { path: '', redirectTo: 'login', pathMatch: 'prefix' },
    ]
  },
  {
    path: 'admin',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'administrator',
        component: AdministratorComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'admin-profile',
            component: AdminProfileComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'admin-reset-password',
            component: AdminResetPasswordComponent,
            canActivate: [AuthGuard],
          },
          { path: '', redirectTo: 'admin-profile', pathMatch: 'full' }
        ]
      },
      {
        path: 'console',
        component: ConsoleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'subs',
        component: SubscriptionComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/subscription/subscription.module').then(m => m.SubscriptionModule),
      },
      {
        path: 'promotion',
        component: PromotionsComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/promotion/promotions.module').then(m => m.PromotionsModule),
      },
      {
        path: 'exercise',
        component: ExerciseComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/exercise/exercise.module').then(m => m.ExerciseModule),
      },
      {
        path: 'program',
        component: ProgramComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/program/program.module').then(m => m.ProgramModule),
      },
      {
        path: 'workout',
        component: WorkoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/workout/workout.module').then(m => m.WorkoutModule),
      },
      {
        path: 'nutrition',
        canActivate: [AuthGuard],
        component: NutritionComponent,
      },
      {
        path: 'admin-notifications',
        canActivate: [AuthGuard],
        component: AdminNotificationsComponent,
      },
      {
        path: 'challenge',
        canActivate: [AuthGuard],
        component: ChallengeComponent,
      },
      {
        path: 'time-challenge',
        canActivate: [AuthGuard],
        component: TimeChallengeComponent,
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        component: ReportsComponent,
      },
      {
        path: 'categories',
        canActivate: [AuthGuard],
        component: CategorieComponent,
      },
      {
        path: 'assign-ondemand',
        canActivate: [AuthGuard],
        component: AssignOndemandComponent,
      },
      { path: '', redirectTo: 'console', pathMatch: 'full' }
    ]
  },
  {
    path: 'nutritionist',
    component: CustomLayoutComponent,
    children: [
      {
        path:'customer',
        component: CustomerListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nutri',
        component: NutriComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'nutri-profile',
            component: NutriProfileComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'nutri-reset-password',
            component: NutriResetPasswordComponent,
            canActivate: [AuthGuard],
          },
          { path: '', redirectTo: 'nutri-profile', pathMatch: 'full' }
        ]
      },
      { path: '', redirectTo: 'customer', pathMatch: 'full' }
    ]
  },
  {
    path: 'main',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'refresh',
        component: HomeComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'user-nutrition',
        canActivate: [AuthGuard],
        component: UserNutritionComponent,
      },
      {
        path: 'workout-exe',
        canActivate: [AuthGuard],
        component: WorkoutExeComponent,
      },
      {
        path: 'ondemand',
        canActivate: [AuthGuard],
        component: OnDemandComponent
      },
      {
        path: 'ondemand-video-list',
        canActivate: [AuthGuard],
        component: OnDemandVideoListComponent
      },
      {
        path: 'ondemand-video-detail',
        canActivate: [AuthGuard],
        component: OnDemandVideoDetailComponent
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent,
      },
      {
        path: 'facebook',
        canActivate: [AuthGuard],
        component: FacebookComponent,
      },
      {
        path: 'info',
        canActivate: [AuthGuard],
        component: InfoComponent
      },
      {
        path: 'user-program',
        canActivate: [AuthGuard],
        component: UserProgramComponent
      },{
        path: 'user',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'profile',
            component: ProfileComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'sub',
            component: SubComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'reset-password',
            component: ResetPasswordComponent,
            canActivate: [AuthGuard],
          },
          { path: '', redirectTo: 'profile', pathMatch: 'full' }
        ]
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' }
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    useHash: true,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
